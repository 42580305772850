<template>
  <div style="min-height: 400px;">
    <el-page-header @back="goBack" :content="`${info[0].fabricName} - ${info[0].colorNumber}`"></el-page-header>
    <el-table :data="info" height="500" @row-click="handleClick" style="margin-top: 20px;">
      <el-table-column align="center" label="订单日期">
        <template #default="scope">{{ $day(scope.row.date).format("YYYY-MM-DD") }}</template>
      </el-table-column>
      <el-table-column align="center" label="缸数">
        <template #default="scope">{{ scope.row.progressInfo.length }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  },

  methods: {
    goBack() {
      this.$emit("back")
    },

    handleClick(row) {
      this.$emit("handle", row)
    }
  }
}
</script>
