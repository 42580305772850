<template>
  <div class="production-process main-panel large">
    <div v-show="!Object.keys(childInfo).length">
      <h3 class="panel-title">染色跟单</h3>
      <el-form :model="form" inline>
        <el-form-item label="胚布名">
          <el-select size="mini" filterable clearable v-model="form.productId" placeholder="请选择胚布名">
            <el-option v-for="item in products" :key="item.id" :label="item.fabricName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" icon="el-icon-search" size="mini" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" height="500" v-loading="isTableLoading" @row-click="handleToChild">
        <el-table-column align="center" label="胚布名" prop="fabricName"></el-table-column>
        <el-table-column align="center" label="色号" prop="colorNumber"></el-table-column>
        <el-table-column align="center" label="累计缸数" prop="cylindersNumSum"></el-table-column>

        <template #append>
          <div class="load-more"
               :class="{'no-more': noMore}"
               v-if="list.length"
               @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}
          </div>
        </template>
      </el-table>
    </div>
    <child-list v-if="Object.keys(childInfo).length" :info="childInfo" @handle="handleClick" @back="childInfo = {}" />

    <my-layer v-if="Object.keys(layerInfo).length"
              title="进度"
              :component="layerComponent"
              @handle="handleLayer"
              :layer-info="layerInfo"
              @close="layerInfo = {}"/>
  </div>
</template>

<script>
import _ from "lodash"
import {shallowRef} from "vue";
import MyLayer from "@/components/MyLayer/MyLayer";
import ChildList from "@/views/production/production-process/ChildList/ChildList";

export default {
  components: {ChildList, MyLayer},
  data() {
    return {
      form: {
        productId: null,
        currentPage: 1,
        pageSize: 50
      },
      list: [],
      noMore: false,
      isTableLoading: false,

      products: [],

      processInfo: {},
      childInfo: {},

      layerComponent: undefined,
      layerInfo: {}
    }
  },

  async mounted() {
    await this.search()
    this.products = await this.$getList.getProducts()
  },

  methods: {
    async search(showLoading = true) {
      this.form.currentPage = 1
      this.noMore = false
      this.list = []
      await this.getList(showLoading)
    },

    async getList(showLoading) {
      if (this.noMore) return false;
      this.isTableLoading = showLoading
      const res = await this.$api.Production.ProductionProcess.getList(this.form).catch(() => {})
      this.isTableLoading = false

      if (res?.code === 200) {
        this.list.push(...res.data.data)
        if (this.list.length >= res.data.total) {
          this.noMore = true
        }
      }
    },

    async handleToChild(row) {
      this.childInfo = _.cloneDeep(row.secondGroup)
    },

    async handleClick(row) {
      console.log(row)
      this.processInfo = _.cloneDeep(row.progressInfo)
      this.processInfo = this.processInfo.map(item => ({
        ...item,
        inputCardNo: item.productionProgress.cardNo || "",
        colorcardId: item.productionProgress.colorcardId || "",
        progress: item.progress.map(process => ({
          ...process,
          name: process.proParentName,
          isRequire: Boolean(process.proParentFlag),
          isShowInProcess: Boolean(process.proParentIsShow),
          child: process.processChild.map(childProcess => ({...childProcess, delflag: 1})),
          isChecked: Boolean(process.activeflag)
        }))
      }))
      this.layerComponent = shallowRef((await import("@/views/production/process/process")).default)
      this.layerInfo = {...this.processInfo}
    },

    handleLayer(data) {
      if (data.mode === "reload") {
        this.form.pageSize = this.list.length
        this.search(false);
        this.form.pageSize = this.$options.data().form.pageSize
      }
      if (data.mode === "close") {
        this.layerInfo = {};
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "production-process";
</style>
